.arrow-link {
    text-transform:uppercase;
    text-align:right;

    &::after {
        content: '\f0a9';
        font-family: 'Font Awesome 5 Free'; // Fontawesome: arrow-circle-right
        font-weight:900;
        margin-left: .4rem;
    }
}
.arrow-link-return {
    text-transform:uppercase;

    &::before {
        content: '\f0a8';
        font-family: 'Font Awesome 5 Free'; // Fontawesome: arrow-circle-left
        font-weight:900;
        margin-right: .4rem;
    }
}

a.dark {
    color: $font-color;

    &::hover {
        color: #kycpa-blue;
    }
}