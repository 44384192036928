/* CPE and Events */
main .event-detail {
    .overview {
        @include media-breakpoint-up(lg) {
            padding-right: 2.5rem;
        }
    }
    div.full-details {
        margin-top: 1rem;
    }
}

.nav-tabs {
    border-color: #666;
    .nav-item {
        font-weight: 700;
        text-transform: uppercase;
    }
    .nav-link {
        color: $kycpa-yellow;
    }
    .nav-link:hover,
    .nav-link:focus {
        border-color: transparent;
        color: $kycpa-blue;
    }
    .nav-link.active {
        border-color: $kycpa-blue #666 #fff;
        border-radius: 0;
        border-top-width: 3px;
    }
    @include media-breakpoint-up(lg) {
        .nav-item {

        }
        .nav-link {
            padding: 1.5rem 1.875rem;
        }
        .nav-link.active {
            border-top-width: 6px;
        }
    }
}

.cpe-tabs {
    justify-content: center;
    flex-wrap: wrap;
    border-color: #666;
    border-bottom: 1px solid #dee2e6;

    > * {
        flex-basis: calc((100% / 5) - .5em);
        margin: .25em;
        min-height: 51px;

        @include media-breakpoint-down(md) {
            flex-basis: calc((100% / 2) - .5em);
        }
    }

    .nav-item {
        border: 1px solid $kycpa-grey-light;
        border-bottom-color: $kycpa-blue;
        border-bottom-width: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .95em;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;

        &::before {
            content: none;
        }

        .nav-link {
            color: $kycpa-yellow;

            &:hover {
                color: $kycpa-grey;
            }
        }

        &.active {
            background: $kycpa-blue;
            border-color: $kycpa-blue;

            .nav-link {
                color: #fff;;
            }
        }
    }
}

.price {
    font-size:1rem;
    margin-bottom:2px;

    &.strike {
        background-color: $kycpa-grey-lightest;
        border: 1px solid $kycpa-grey-light;
        color: lighten( $kycpa-grey, 15% );
        font-weight: normal;
        //font-style: italic;
        //text-decoration: line-through;
    }
}

.related_events .card {
    margin-bottom: 1rem;
}

.option {
    label {
        margin-bottom:.5rem;
    }
    span.fee {
        display: inline-block;
        font-size: 0.9rem;
        margin-left:1em;

        em {
            padding-right:.6em;
        }
    }
}

.guest-block {
    margin: 1rem 0;
    position: relative;

    h3, h4 {
        margin: 1rem 0;
    }

    a.button-delete {
        position: absolute;
        top: .5rem;
        right: 1rem;
    }
}
.credits-registration {
    .open, .closed, .cancelled {
        padding:.6rem 1rem;
        text-align:center;
    }
    .open {
        background-color: $kycpa-green;
    }
    .closed {
        background-color: $kycpa-red;
        color: $white;
    }
    .cancelled {
        background-color: $kycpa-yellow;
    }
}
main .event-detail {
    div.option {
        .card {
            color: lighten($kycpa-grey, 20%);
            margin-bottom:.6rem;
            label.input {
                color: $kycpa-blue;
            }
            .card-body {
                padding:.6rem 1rem;
            }

            label h3 {
                color: inherit;
                font-size:1.35rem;
                margin: 0;
            }

            .option-details {
                p:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        &.active {
            .card {
                border-color: $kycpa-grey;
                color: $kycpa-grey;

                label.input {
                    font-weight: bold;
                }
            }
        }
    }
}
