.table-cart {
    h4 {
        margin-top: 1rem;
        margin-bottom: .75rem;
    }

    td.description {
        width: 70%;
    }
}

.price {
    text-align: right;
    .price-subhead {
        font-size: 1.25rem;
        text-transform: uppercase;
    }
    .price-head {
        color: $kycpa-blue;
        font-size: 2.25rem;
        line-height: 1;
        margin-bottom: 0;
    }
}
p.checkout_helper {
    text-align: right;
    margin-top: -20px;
    font-size: 13px;
    margin-right: -15px;
}


.form-control iframe {
	height: 100%;
	width: inherit;
	border: 0;
}


//
// Credit Cards
//
.cards {
    margin-bottom: 2em;
  
    .card {
      position: relative;
      padding: 1em 0.5em;
      border: 1px solid darkgrey;
      margin: 0.5em 0;
  
      &:after {
        display: block;
        content: "";
        width: 100%;
        padding-top: 25px;
      }
  
      i {
        margin: 0 0.5em;
      }
  
      p {
        position: absolute;
        top: 0.45em;
        margin: 0;
      }
  
      .type {
        left: 65px;
  
        a {
          font-size: 0.75em;
          color: $kycpa-blue;
        }
  
      }
  
      .number {
        right: 0.5em;
        left: auto;
  
        span {
          font-size: 0.85em;
          color: darkgrey;
        }
      }
    }

    label {
      margin-top: 2em;
    }
  }
  
  .hidden {
    display: none;
  }
  
  //Authorize.net Seal
  
  .affinipay_seal {
    display: block;
    margin: 0 auto !important;
    height: 47px;
  }