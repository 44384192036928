.btn {
    &::before {
        font-family: 'Font Awesome 5 Free';
        margin-right: 1rem;
        padding: 1rem 1rem 1rem 0;
    }
}
.btn-gradient,
.custom-file-label::after {
    @include gradient-y($kycpa-blue, $kycpa-blue-darkest, 0%, 100%);
    border-radius: 0;
    color: $white;
    &:hover {
        color: $white;
    }
    &:active {
        @include gradient-y($kycpa-blue, $kycpa-blue-darkest, 0%, 100%);
    }
}

button, 
input[type="submit"],
.button {
    @extend .btn, .btn-gradient;

    border: none;
}
.button-sm {
    @extend .btn, .btn-sm, .btn-gradient;
}
.button-delete {
    @extend .btn, .btn-sm, .btn-danger;

    background-color: $kycpa-red;
    color: $white !important;
    margin-right: .4rem;

    &::before {
        color: $white;
        content: '\f057'; // Fontawesome: times-circle
        margin-right: .4rem;
        padding: 0;
    }

    &:hover {
        background-color: darken($kycpa-red, 10%);
    }
}
.button-action {
    @extend .btn, .btn-sm, .btn-outline-primary;

    border-color: $kycpa-blue;
    color: $kycpa-blue !important;
    margin-right:.4em;

    &:hover {
        color: $white !important;
    }

    &::before {
        margin-right: .4rem;
        padding: 0;
        font-weight: 900;
    }
}
.text-right {
    .button-delete, 
    .button-action {
        margin-left: .4rem;
        margin-right: 0;
    }
}
.button-link {
    @extend .button-action;

    &::before {
        content: '\f35d'; // Fontawesome: external-link-alt
    }
}
.button-go {
    @extend .button-link;

    border-color: darken($kycpa-green, 15%);
    background-color: darken($kycpa-green, 15%);
    color: $white !important;

    &:hover {
        background-color: darken($kycpa-green, 10%);
        border-color: darken($kycpa-green, 10%);
    }
}
.button-edit {
    @extend .button-action;

    &::before {
        content: '\f044'; // Fontawesome: edit
    }
}
.button-add {
    @extend .button-edit;

    &::before {
        font-weight:500;
    }
}
.button-email {
    @extend .button-action;

    &::before {
        content: '\f0e0'; // Fontawesome: envelope
    }
}
.button-download {
    @extend .button-action;

    &::before {
        content: '\f381'; // Fontawesome: cloud-download-alt
    }
}
.button-upload {
    @extend .button-action;

    &::before {
        content: '\f382'; // Fontawesome: cloud-upload-alt
    }
}
.button-search-plus {
    @extend .button-action;

    &::before {
        content: '\f00e'; // Fontawesome: search-plus
    }
}
.button-cpe-tracker {
    @extend .button-action;

    &::before {
        content: '\f022'; // Fontawesome: list-alt
        font-weight: 500;
    }
}
.button-enter {
    @extend .button-go;

    &::before {
        content: '\f2f6'; // Fontawesome: sign-in-alt
        font-weight: 900;
    }
}
.button-exit {
    @extend .button-delete;

    &::before {
        content: '\f2f5'; // Fontawesome: sign-out-alt
        font-weight: 900;
    }
}
.button-toggle {
    @extend .button-action;

    &::before {
        content: '\f0fe'; // Fontawesome: plus-square
        font-weight: 500;
    }
}
.button-print {
    @extend .button-action;

    &::before {
        content: '\f02f'; // Fontawesome: print
        font-weight: 900;
    }
}
.badge-success {
    background-color: $kycpa-green;
    margin: 0 .7em;
}