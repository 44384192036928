.test-print {
    font-size: 1rem; 

    .no-print {
        //display: none;
    }
    #mobile-menu, #main-navigation, footer {
        display: none;
    }
    .container {
        width: 800px;
    }
    .footer-bottom {
        background-color: $white;
        color: $font-color;

        a:link {
            color: $font-color;
        }
    }

    section {
        margin-bottom: 1rem;
    }


    .table {
        margin: 2rem 0;

        th {
            background-color: transparent;
            color: $font-color;
            font-size: 1rem;
        }
    }
}

#dues-print {
    header {
        padding: 1.5em 0;
    }
    table {
        width: 100%;
    }
    tr {
        &:first-child {
            border-bottom: 1px solid grey;
            font-weight: 600;
            text-transform: uppercase;
        }
        &.total {
            border-top: 1px solid grey;
            font-weight: 600;
            text-transform: uppercase;
        }
        &.footnote {
            font-size: .8em;
            font-style: italic;
            text-align: left;
        }
    }
    td {
        padding: .5em 0;
    }
    section {
        margin: 0;
        padding: 1.5em 0;
    }
    section#cc-form {
        border-top: 4px solid #ffc524;
        border-bottom: 4px solid #ffc524;
        max-width: 900px;
        margin: auto;
        color: #0073ae;

        h5 {
            text-align: center;
            text-decoration: underline;
        }

        .notice {
            border-top: 2px solid #ffc524;
            text-align: center;
            padding-top: 1.5em;
            color: $font-color;
        }
    }
    .container {
        width: 900px;
    }
    .two-column {
        display: flex;
        justify-content: center;

        > * {
            flex-basis: 50%;
        }

        @media screen and (min-width: 992px){
            > * {
                flex-basis: 66%;
            }

            > * + * {
                flex-basis: 33%;
            }
        }

        &.credit-form {
            align-items: center;
            > * {
                flex: 3;
                flex-basis: auto;
                padding: .5em;
            }
            > *:first-child {
                flex: 1;
                flex-basis: 25%;
                font-weight: 700;
                text-align: center;
            }
            tr {
                border: none;
                text-transform: none;
                font-weight: 500;
            }
        }
    }
    .flex {
        display: flex;
        color: #0073ae;
    }
    .flex > * {
        flex: 1;
    }
    .flex > *:nth-child(2) {
        flex: 2;
    }
    .flex > *:nth-child(3) {
        flex: 3;
    }
    blockquote, .box {
        border: 3px solid #ffc524;
        padding: .5em;

        &.-blue {
            border-color: #0073ae;
            background-color: #0073ae;
            color: #FFF;
        }

        &.quote {
            font-size: 0.9em;

            footer {
                &::before {
                    all: unset;
                }
                margin-top: 0.5em;
                font-size: 0.8em;
                display: block;
            }
            cite {
                font-weight: 600;
            }
        }
    }
    .box p {
        margin-bottom: 0;
    }
    .footnote {
        text-align: center;
    }
    .small {
        text-align: left;
    }
    .callout {
        background-color: #0073ae;
        color: #FFF;
    }
}