////
/// KYCPA
///
///
/// Base
/// Structure
/// Cards
@import '../../../node_modules/bootstrap/scss/bootstrap';
$font-color: #212529;
$kycpa-grey-darkest: #3d4852;
$kycpa-grey-darker: #606f7b;
$kycpa-grey-dark: #8795a1;
$kycpa-grey: #515151;
$kycpa-grey-light: #ACACAC;
$kycpa-grey-lightest: #f0f0f0;
$kycpa-green: #38c259;
$kycpa-red: #d62938;
$kycpa-yellow: #ffce2e;
$kycpa-blue: #387fc2;
$kycpa-blue-dark: #0d3a59;
$kycpa-blue-darkest: #091724;
$kycpa-callout: rgba(110, 110, 110, 0.1);
$theme-colors: map-merge(( "primary": $primary, "secondary": $secondary, "success": $kycpa-green, "info": $info, "warning": $warning, "danger": $kycpa-red, "light": $light, "dark": $dark, "kycpa-grey-darkest": $kycpa-grey-darkest, "kycpa-grey-darker": $kycpa-grey-darker, "kycpa-grey-dark": $kycpa-grey-dark, "kycpa-grey": $kycpa-grey, "kycpa-grey-light": $kycpa-grey-light, "kycpa-grey-lightest": $kycpa-grey-lightest, "kycpa-green": $kycpa-green, "kycpa-red": $kycpa-red, "kycpa-yellow": $kycpa-yellow, "kycpa-blue": $kycpa-blue, "kycpa-blue-dark": $kycpa-blue-dark, "kycpa-blue-darkest": $kycpa-blue-darkest), $theme-colors);
$enable-gradients: true !default;
// Base
html {
    font-size: 14px;
    @include media-breakpoint-up(md) {
        font-size: 16px;
    }
}

body {
    color: $font-color;
    line-height: 1.25;
    font-family: 'Open sans', Arial, sans-serif;
}

a {
    color: $kycpa-blue;
    &:hover {
        color: darken($kycpa-blue, 7%);
        text-decoration: none;
    }
}

// Structure
.torso {
    overflow-x: hidden;
}

main,
aside.primary {
    padding: 3rem 0 1.5rem 1rem;
}

aside.primary {
    background-color: $kycpa-grey-lightest;
    background-image: linear-gradient(to right, #D5D5D5 0%, $kycpa-grey-lightest 8px);
    position: relative;
    &::after {
        background-color: $kycpa-grey-lightest;
        content: '';
        display: block;
        height: 100%;
        left: 100%;
        position: absolute;
        top: 0;
        width: 9999px;
    }
    .module {
        padding: 1.5rem;
    }
    .module p:last-child {
        margin-bottom: 0;
    }
    .refiners {
        margin-bottom: 3rem;
    }
}

nav.supplemental {
    h5 {
        margin-bottom: 1.2rem;
        a {
            color: $kycpa-blue;
            font-weight: 600;
        }
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            margin-bottom: 1rem;
            a {
                border-bottom: 1px solid $kycpa-blue;
                color: $kycpa-blue-dark;
                display: block;
                padding-bottom: 0.5rem
            }
        }
    }
}

header {
    padding-bottom: $spacer;
    padding-left: $spacer * 2 !important;
    padding-right: $spacer * 2 !important;
    padding-top: $spacer;
    @include media-breakpoint-up(md) {
        padding: $spacer 0 0;
    }
    .logo {
        display: block;
        max-width: 100%;
        width: 5rem;
        @include media-breakpoint-up(md) {
            width: 7rem;
        }
    }
    #mobile-menu {
        color: $white;
        font-weight: 700;
        padding: 1rem;
        text-align: right;
        text-decoration: none;
        text-transform: uppercase;
        @include gradient-y($kycpa-blue, $kycpa-blue-dark, 0%, 100%);
    }
    #main-navigation {
        display: flex;
        flex-direction: column;
    }
    .top-navigation {
        border-top: 1px solid $kycpa-yellow;
        margin-top: 1rem;
        order: 2;
        padding: 1rem 0;
        text-align: center;
        @include media-breakpoint-up(md) {
            border-bottom: 1px solid $kycpa-yellow;
            border-top: 0;
            margin-top: 0;
            order: 1;
            padding: 0;
            text-align: right;
        }
        a {
            color: $kycpa-grey;
            display: block;
            font-size: $font-size-base * 0.875;
            font-weight: 500;
            text-transform: uppercase;
            @include media-breakpoint-up(md) {
                display: inline-block;
                font-size: $font-size-base * 0.75;
                margin-left: 1.5rem;
            }
            @include media-breakpoint-up(lg) {
                font-size: $font-size-base * 0.875;
            }
            @include media-breakpoint-down(md) {
                &:nth-child(n+0) {
                    padding: 1rem 0;
                }
            }
            &.btn {
                margin: 1rem 0;
            }
        }
        form {
            display: inline;
            input {
                background-color: $kycpa-grey-lightest;
                display: inline-block;
                margin: 1rem 0;
                max-width: 200px;
                text-align: center;
                @include media-breakpoint-up(md) {
                    margin: 0 0 0 1.5rem;
                    text-align: left;
                }
            }
        }
    }
    nav.nav {
        display: block;
        order: 1;
        padding-left: 0;
        padding-right: 0;
        padding-top: 1rem;
        .dropdown {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 0;
            @include media-breakpoint-up(md) {
                padding-bottom: 1rem;
            }
        }
        a {
            color: $kycpa-blue;
            display: block;
            font-size: $font-size-base;
            font-weight: 600;
            padding: 1rem 0;
            text-align: center;
            text-transform: uppercase;
            @include media-breakpoint-up(md) {
                display: inline-block;
                font-size: $font-size-base *.8;
                padding: 0;
                text-align: left;
            }
            @include media-breakpoint-up(lg) {
                font-size: $font-size-base;
            }
        }
        .dropdown-toggle {
            background-color: $kycpa-blue;
            color: #FFF;
            border: none;
            border-radius: 4px;
            line-height: 1;
            margin-left: auto;
            padding: 3px 5px;
            vertical-align: top;
        }
        .dropdown-toggle::after {
            margin-left: 0;
            vertical-align: middle;
        }
        .dropdown-menu {
            border: 1px solid #DADADA;
            border-top: 4px solid $kycpa-blue;
            border-radius: 0;
            box-shadow: 3px 3px 8px 0 rgba($kycpa-blue-darkest, .085);
            flex: 0 0 100%;
            margin-top: 0;
            vertical-align: top;
        }
        .dropdown-menu a {
            color: $kycpa-blue;
            font-size: 0.85rem;
            font-weight: 400;
            margin-bottom: 0;
            padding: 0.45rem 0.75rem;
        }
        .dropdown-menu a:hover {
            background-color: $kycpa-blue;
            color: #FFF;
        }
        @include media-breakpoint-up(md) {
            align-items: center;
            display: flex;
            justify-content: space-between;
            order: 2;
            .dropdown {
                display: block;
            }
            .dropdown-toggle {
                pointer-events: none;
                background: transparent;
                color: $kycpa-blue;
                line-height: inherit;
                margin-left: 0;
                padding: 0;
            }
            .dropdown:hover>.dropdown-menu {
                display: block;
            }
        }
    }
    i.fa-shopping-cart {
        margin-right: 0.4em;
    }
}

img {
    max-width: 100%;
    height: auto;
}

section.hero {
    border-top: 10px solid $kycpa-blue;
    position: relative;
    img {
        width: 100%;
    }
    .hero-text {
        background: rgba($white, 0.9);
        width: 100%;
        padding: 2rem 1.5rem;
        @include media-breakpoint-up(md) {
            position: absolute;
            right: 4rem;
            top: 1rem;
            width: 50%;
        }
        @include media-breakpoint-up(lg) {
            top: 2rem;
            width: 40%;
        }
        @include media-breakpoint-up(xl) {
            top: 4rem;
        }
        span {
            display: block;
            &.hero-text--title {
                text-transform: uppercase;
                font-weight: 700;
                color: $kycpa-blue;
                margin-bottom: 0.75rem;
            }
            &.hero-text--meta {
                color: $kycpa-grey-darkest;
                font-size: .875rem;
            }
        }
        h1 {
            font-size: 1.5rem;
            @include media-breakpoint-up(lg) {
                font-size: 1.875rem;
            }
            @include media-breakpoint-up(xl) {
                font-size: 2.25rem;
            }
        }
        .btn {
            padding: 1rem 3.5rem;
            margin-top: 2rem;
            font-weight: bold;
        }
    }
}

// Cards
.card,
.card-deck .card {
    margin-bottom: 2rem;
}

.card-deck-duo {
    .card {
        flex: 0 0 calc(50% - #{$card-deck-margin} * 2);
    }
}

.card-deck-trio {
    .card {
        flex: 0 0 calc((100% / 3) - (#{$card-deck-margin} * 2));
    }
}

.card {
    border-top: 4px solid $kycpa-blue;
    box-shadow: 0 16px 20px rgba(0, 0, 0, 0.15);
    .card-title {
        color: #6e6e6e;
        font-size: 1.25rem;
        margin-top: 0;
        text-transform: uppercase;
    }
    .card-action a {
        color: $kycpa-blue-dark;
        display: block;
        margin-top: 2rem;
        text-transform: uppercase;
        &::after {
            content: '\f0a9';
            font-family: 'Font Awesome 5 Free';
            margin-left: .5rem;
        }
    }
}

.kycpa-card {
    border-top: 4px solid $kycpa-blue;
    box-shadow: 0 22px 26px rgba(0, 0, 0, 0.18);
    margin-bottom: 3rem;
    padding: 1.5rem;
    @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
    }
    h3 {
        color: #6e6e6e;
        font-size: 1.25rem;
        margin-top: 0;
        text-transform: uppercase;
    }
    p {
        font-size: 1.125;
    }
    .kycpa-card-link {
        color: $kycpa-blue-dark;
        display: block;
        margin-top: 2rem;
        text-transform: uppercase;
        &::after {
            content: '\f0a9';
            font-family: 'Font Awesome 5 Free';
            margin-left: .5rem;
        }
    }
}

// EVENTS
.events-and-feed {
    margin: 4rem auto;
    @include media-breakpoint-up(lg) {
        display: flex;
    }
    h2 {
        font-size: 1.25rem;
        color: $kycpa-grey;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 2rem;
    }
}

.upcoming-events,
.feed {
    flex: 0 1 50%;
}

.upcoming-events {
    @include media-breakpoint-up(lg) {
        padding-right: 1.5rem;
    }
}

.feed {
    @include media-breakpoint-up(lg) {
        padding-left: 1.5rem;
    }
}

.events-link,
.events-link--all {
    a {
        display: block;
        text-align: right;
        text-decoration: none;
        color: $kycpa-blue;
        text-transform: uppercase;
        font-weight: 500;
        i {
            margin-left: 1rem;
        }
    }
}

.events-link {
    margin-top: 2rem;
}

.events-link--all {
    border-top: 2px solid $kycpa-blue-darkest;
    padding-top: 1rem;
    a {
        color: $kycpa-blue-darkest;
    }
}

.events {
    margin: 4rem 0;
}

.event-code {
    h4 {
        margin: 1.4rem 0 .6rem 0;
    }
    p.h3 {
        margin: 0 0 2rem 0;
    }
}

.event {
    display: flex;
    .event-data {
        margin-right: 1rem;
    }
    .credits {
        background: $kycpa-blue;
        padding: .5rem;
        color: $white;
        max-width: 100px;
        span {
            display: block;
            text-align: center;
            font-size: $font-size-base * .875;
            text-transform: uppercase;
            &:first-child {
                font-size: 1.5rem;
                font-weight: 700;
            }
        }
    }
    .credits-meta {
        background: $kycpa-blue-dark;
        color: $white;
        max-width: 100px;
        span {
            display: block;
            margin-top: .25rem;
            padding: .25rem;
            text-align: center;
            font-size: $font-size-base * .75;
        }
    }
    .event-info {
        span {
            display: block;
            &:first-child {
                color: $kycpa-blue-darkest;
                font-weight: 700;
                margin-bottom: .5rem;
            }
            &:last-child {
                font-weight: 600;
                margin-bottom: .5rem;
                em {
                    font-weight: 400;
                }
            }
        }
        h3 {
            font-weight: 400;
            font-size: 1.5rem;
            margin-bottom: .5rem;
        }
    }
}

.upcoming-courses {
    .event {
        justify-content: space-between;
    }
}

.nav-featured {
    background: rgba($yellow, 0.95);
    font-size: 1rem;
    @include media-breakpoint-up(md) {
        position: absolute;
        width: 100%;
        bottom: 0;
    }
    .featured-links {
        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    h3 {
        font-size: 1.25rem;
        text-transform: uppercase;
        padding: 1rem 0;
        margin: 0;
    }
    a {
        display: block;
        color: $kycpa-grey-darkest;
        text-decoration: none;
        padding: 1rem 0;
        @include media-breakpoint-up(md) {
            display: inline-block;
            padding: 1.25rem 0;
        }
        @include media-breakpoint-up(lg) {
            padding: 1.4rem 0;
        }
        &.active,
        &:hover {
            color: $white;
        }
    }
}

.employment-resources--background {
    margin: 6rem 0;
    color: $white;
    @include gradient-x($kycpa-blue, $kycpa-blue-dark, 0%, 100%);
    @include media-breakpoint-up(md) {
        padding: 3rem 0;
    }
    .container {
        @include media-breakpoint-down(md) {
            padding: 0;
            max-width: 100%;
        }
    }
}

.employment-resources {
    @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
    }
}

.employment-resources--image {
    @include media-breakpoint-up(md) {
        padding-right: 3rem;
        width: 50%;
    }
    img {
        width: 100%;
        display: block;
    }
}

.employment-resources--text {
    @include media-breakpoint-up(md) {
        width: 50%;
    }
    @include media-breakpoint-down(md) {
        padding: 2rem;
    }
    span {
        display: block;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    h2 {
        font-size: 2.25rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }
    p {
        font-size: 1.125rem;
        line-height: 1.5;
    }
    .btn {
        display: block;
        @include media-breakpoint-up(sm) {
            display: inline-block;
        }
    }
    .btn-white {
        background: $white;
        color: $kycpa-grey-darkest;
        font-weight: 700;
        margin-top: 2rem;
        padding: 1rem 2rem;
    }
}

// News
.news {
    margin-top: 4rem;
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
}

.news-item {
    margin-bottom: 4rem;
    transition: 0.3s ease-in-out;
    &:hover {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
        transition: 0.3s ease-in-out;
    }
    @include media-breakpoint-up(md) {
        width: 40%;
    }
    img {
        display: block;
        max-width: 100%;
    }
}

.news-item--text {
    padding: 1rem;
    span,
    a {
        display: block;
    }
    span {
        color: $kycpa-grey;
        text-transform: uppercase;
        margin: .5rem 0;
    }
    h3 {
        font-size: 1.5rem;
        color: $kycpa-blue-darkest;
        margin-bottom: .5rem;
    }
    p {
        color: $kycpa-grey;
        border-bottom: 2px solid $kycpa-grey-darkest;
        padding-bottom: 2rem;
    }
    .arrow-link {
        color: $font-color;
        font-weight: bold;
    }
}

// Testimonial
.testimonial {
    padding: 2rem;
    @include gradient-x($kycpa-blue, $kycpa-blue-dark, 0%, 100%);
    .flex-container {
        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .testimonial-image {
        @include media-breakpoint-up(md) {
            width: 33.333%;
        }
        @include media-breakpoint-up(lg) {
            width: 25%;
        }
        img {
            display: block;
            width: 12rem;
            border-radius: 100%;
            margin: 0 auto;
            @include media-breakpoint-up(md) {
                width: 100%;
            }
        }
    }
    .testimonial-text {
        blockquote {
            color: $white;
            @include media-breakpoint-up(md) {
                padding-left: 2rem;
            }
            span {
                display: block;
                font-weight: bold;
                font-size: 2.25rem;
            }
            p {
                line-height: 1.5;
                font-size: 1.5rem;
                margin-bottom: 2rem;
            }
            cite {
                font-weight: 500;
                font-style: normal;
                em {
                    font-style: italic;
                    font-weight: 300;
                }
            }
        }
    }
}

// Ads
.advertisements {
    display: block;
    margin-top: 4rem;
    margin-bottom: 4rem;
    img {
        width: 100%;
        display: block;
        margin-bottom: 1rem;
    }
    @include media-breakpoint-up(sm) {
        display: flex;
        justify-content: space-between;
        padding: 0 1.5rem;
        img {
            margin-bottom: 0;
        }
    }
}

aside {
    section.ads {
        text-align: center;
        a {
            display: block;
            margin: 1rem 0;
        }
        img {
            max-width: 100%;
        }
    }
}

div.credit-table {
    margin-bottom: 1rem;
    .row {
        @extend .justify-content-start,
        .mb-1;
        div.text-right {
            padding-right: 0;
        }
    }
}

.intro {
    color: $kycpa-blue-dark;
    font-size: 1.1rem;
    margin-top: 1em;
}

// Footer
footer {
    line-height: 1.5;
    &:before {
        display: block;
        content: '';
        height: 1rem;
        @include gradient-x($kycpa-blue, $kycpa-blue-dark, 0%, 100%);
    }
    .footer-container {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .footer-logo {
        display: block;
        margin: 0 auto 3rem auto;
        width: 12rem;
        @include media-breakpoint-up(md) {
            width: 100%;
        }
    }
    .footer-text {
        margin-bottom: 2rem;
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
        h4 {
            text-transform: uppercase;
            color: $kycpa-grey;
            font-bold: 700;
            display: flex;
            align-items: center;
            @include media-breakpoint-up(md) {
                margin-bottom: 1.5rem;
            }
            &:after {
                display: block;
                content: '';
                border: 1px solid $kycpa-grey-light;
                height: 1px;
                width: 100%;
                margin-left: 1rem;
            }
        }
        span {
            display: block;
            color: $kycpa-blue-dark;
        }
    }
    .social {
        a {
            text-decoration: none;
            font-size: 1.5rem;
        }
        .instagram {
            color: #3f729b;
        }
        .facebook {
            color: #3b5998;
        }
        .youtube {
            color: #e52d27;
        }
        .linkedin {
            color: #0976b4;
        }
        .twitter {
            color: #55acee;
        }
    }
}

// Footer bottom
.footer-bottom {
    background-color: $kycpa-grey-lightest;
    color: $kycpa-blue-dark;
    padding: 1rem 0;
    text-align: center;
    @include media-breakpoint-up(md) {
        font-size: $font-size-base * .8;
        text-align: left;
    }
    @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
    }
    a {
        color: inherit;
        display: block;
        margin-bottom: 1rem;
        margin-left: 1rem;
        margin-top: 1rem;
        @include media-breakpoint-up(md) {
            display: inline-block;
            margin-bottom: 0;
            margin-top: 0;
            &::before {
                content: '|';
                margin-right: 1rem;
            }
        }
        &:first-child::before {
            content: '';
        }
    }
}

.dropdown-menu {
    margin: 0.5rem 0 0;
    @include media-breakpoint-down(md) {
        position: relative;
        top: auto;
        left: auto;
        float: none;
        min-width: 100%;
    }
    //padding: .5rem;
    .dropdown-item {
        color: $kycpa-blue-dark;
        margin-bottom: .5rem;
        padding: .5rem;
        text-transform: none;
    }
}

.dropdown-toggle {
    outline: 0;
}

// Forms
legend {
    color: $kycpa-blue-dark;
    padding: 1.2rem 0 1rem 0;
}

// Alerts 
.alert {
    font-weight: bold;
    border: 0;
    border-radius: 0;
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    &::before {
        border-width: 0 1px 0 0;
        border-style: solid;
        font-family: 'Font Awesome 5 Free';
        font-size: 2rem;
        margin-right: 1rem;
        padding: 1rem 1rem 1rem 0;
    }
    &.alert-success {
        background-color: $kycpa-green;
        color: white;
        &::before {
            border-color: white;
            content: '\f00c';
        }
    }
    &.alert-danger {
        background-color: $kycpa-red;
        color: white;
        &::before {
            border-color: white;
            content: '\f00d';
        }
    }
    &.alert-info {
        background-color: $kycpa-blue-dark;
        color: white;
        &::before {
            border-color: white;
            content: '\f0f3';
        }
    }
    &.alert-warning {
        background-color: $kycpa-yellow;
        color: $kycpa-blue-darkest;
        &::before {
            border-color: $kycpa-blue-darkest;
            content: '\f06a';
        }
    }
}

.text-success {
    color: $kycpa-green !important;
}

.text-danger {
    color: $kycpa-red !important;
}

.text-info {
    color: $kycpa-blue-dark !important;
}

.text-warning {
    color: $kycpa-yellow !important;
}

// Callouts
.callout {
    background-color: $kycpa-callout;
    background-image: url('../img/swish-grey.svg');
    background-repeat: no-repeat;
    background-position: 0 11rem;
    background-size: 118%;
    overflow: hidden;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    h3 {
        font-size: 1.25rem;
        text-transform: uppercase;
        margin-top: 0;
    }
    p {
        font-size: 1.5rem;
    }
    .action {
        margin: 2rem auto 1rem auto;
        text-align: center;
        .btn-gradient {
            background-image: linear-gradient(#397ec0, #0d3a59);
            font-size: 0.75rem;
            font-weight: bold;
            padding: 1rem;
        }
    }
}

// Tables 
.table:not(.table-cart) {
    tr:nth-child(2n+3) {
        background-color: rgba(240, 240, 240, .5);
    }
    th {
        background-color: $kycpa-blue;
        color: white;
        font-weight: bold;
        padding: 1rem;
        text-transform: uppercase;
    }
}

.table {
    caption {
        caption-side: top;
        font-size: 2rem;
        padding: 0.5em;
    }
}

.layered {
    section {
        margin-bottom: 3rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

// Typeography
main {
    // Headings
    h1,
    .h1 {
        font-size: 2.25rem;
        font-weight: bold;
    }
    h2,
    .h2 {
        color: #6e6e6e;
        font-size: 1.5rem;
        font-weight: bold;
        margin-top: 2rem;
        margin-bottom: 1.375rem;
        text-transform: uppercase;
    }
    h3,
    .h3 {
        color: $kycpa-blue;
        font-size: 1.5rem;
        font-weight: bold;
        margin-top: 2rem;
        margin-bottom: 1.375rem;
    }
    h4,
    .h4 {
        color: $kycpa-blue-darkest;
        font-size: 1.125rem;
        font-weight: bold;
        margin-top: 2rem;
        margin-bottom: 1.375rem;
    }
    .card {
        &.tabbed {
            border-top: 0;
        }
        &.card-body,
        .card-body {
            h2.card-title,
            h3.card-title,
            h4.card-title,
            h5.card-title {
                margin-top: 0;
            }
            p.card-text {
                font-size: 1rem;
            }
        }
    }
    // Paragraph
    p {
        font-size: 1.125rem;
        line-height: 1.5;
    }
    .disclaimer {
        p {
            color: $kycpa-grey-darkest;
            font-size: .9rem;
            &::last-of-type {
                margin-bottom: 0;
            }
        }
    }
    // Lists
    ul,
    ol {
        list-style: none;
        li {
            margin-bottom: 2rem;
            &::before {
                color: $kycpa-blue;
                display: inline-block;
                margin-left: -1rem;
                width: 1rem;
            }
        }
    }
    ul li {
        position: relative;
        &::before {
            content: '\2022';
            font-size: 1.4rem;
            position: absolute;
            top: -3px;
        }
    }
    div.listing ul li,
    ul.pagination li,
    ul.list-unstyled li,
    ul li.nav-item {
        &::before {
            content: '';
            font-size: 1rem;
            position: relative;
        }
    }
    ul.list-unstyled li {
        margin-bottom: 0;
        .media-body {
            border-bottom: 1px solid $kycpa-grey-lightest;
            padding: 1.6rem 0;
            h2 {
                margin-top: 0;
            }
        }
    }
    ul.select2-selection__rendered {
        li {
            margin-bottom: 0;
            &::before {
                content: '';
                font-size: 1rem;
                position: relative;
            }
        }
    }
    div.listing {
        ul {
            padding-left: 0;
            li {
                margin-bottom: 0;
                h2,
                h3,
                h4 {
                    margin: 0;
                }
            }
        }
        div {
            h2,
            h3,
            h4 {
                margin: 0;
            }
        }
        .card {
            margin-bottom: 1rem;
        }
    }
    ol {
        counter-reset: li;
        li {
            counter-increment: li;
            &::before {
                content: counter(li)'.';
                font-weight: bold;
                margin-right: .75rem;
            }
        }
    }
    .list-group-item {
        p {
            font-size: 1rem;
        }
    }
    .col-box {
        background-color: $white;
        border-top: 3px solid $kycpa-blue;
        -webkit-box-shadow: 0 4px 6px 2px $kycpa-grey-light;
        -moz-box-shadow: 0 4px 6px 2px $kycpa-grey-light;
        box-shadow: 0 4px 6px 2px $kycpa-grey-light;
        h2,
        h3,
        h4,
        h5 {
            font-size: 1.2rem;
            margin-top: .4rem;
        }
        p {
            font-size: 1rem;
        }
        ul li {
            h3,
            h4 {
                font-size: 1.1rem;
                margin-top: .3rem;
            }
        }
        .arrow-link {
            font-size: .9rem;
        }
    }
    .message-bar {
        padding: 1rem 0;
        h3 {
            color: $font-color;
            margin: 0;
            padding: 0;
        }
        .message-content {
            display: flex;
            justify-content: left;
            .message-details {
                padding: 0 0 0 2rem;
            }
        }
        &.danger {
            background-color: $kycpa-red;
        }
        &.success {
            background-color: $kycpa-green;
        }
        &.warning {
            background-color: $kycpa-yellow;
            h3 {
                border-right: 2px solid $font-color;
                padding-right: 2rem;
            }
        }
    }
}

.heading-title {
    background-color: $kycpa-blue-darkest;
    border-top: 0.5rem solid $kycpa-blue;
    h1 {
        color: white;
        font-size: $font-size-base * 2;
        font-weight: 900;
        margin: 0;
        padding: 2rem 0;
        @include media-breakpoint-up(md) {
            font-size: $font-size-base * 2.5;
        }
    }
}


/*
.two-column {
    margin: 0;
    padding-top: 2rem;
}

aside.two-column {
    background-color: #f0f0f0;
    box-shadow: inset 17px 0px 19px -27px rgba(0, 0, 0, 0.35);
}
*/

.help {
    color: darken($kycpa-grey-light, 15%);
    font-size: 1rem;
    font-style: italic;
}

.required {
    color: $kycpa-red;
}

div.action {
    margin: 1rem 0;
    a,
    button,
    input {
        margin-right: 1rem;
    }
    .text-right {
        a,
        button,
        input {
            margin-right: 0;
        }
    }
}

.meta {
    font-style: italic;
}

.well {
    background-color: $kycpa-grey-lightest;
    border: 1px solid darken($kycpa-grey-lightest, 10%);
    border-radius: 4px;
    box-shadow: inset 0px 24px 10px -20px rgba(255, 255, 255, 0.4), 2px 2px 15px 2px rgba(0, 0, 0, 0.1);
    color: $font-color;
    padding: 1.25rem 1.5rem;
    @include media-breakpoint-up(lg) {
        padding: 1.75rem 2rem;
    }
}

#certificate {
    main {
        .container {
            max-width: 800px;
            h2,
            h3,
            h4 {
                color: $font-color;
                font-style: italic;
                text-align: center;
            }
            h4 {
                margin-top: 0;
            }
            label {
                font-style: italic;
            }
            .address-contact {
                font-size: .85rem;
                line-height: 1.4rem;
                padding-right: 0;
                text-align: right;
            }
            .details {
                border: 2px solid $font-color;
                border-collapse: collapse;
                margin: 2rem 0;
                width: 100%;
                td {
                    border: 2px solid $font-color;
                    padding: 8px;
                    vertical-align: top;
                }
            }
            .signature {
                text-align: right;
                img {
                    max-height: 100px;
                }
            }
            .col .cell {
                border: 2px solid $font-color;
                margin: 0 0 0 -10px;
                padding: 8px 0;
                .balance-text {
                    font-style: italic;
                    padding: 0 30px 0 6px;
                    text-transform: uppercase;
                }
            }
        }
    }
}


/* TinyMCE */

img.justified_right {
    float: right;
    margin-left: 1em;
    margin-bottom: 1em;
}

img.justified_left {
    float: left;
    clear: left;
    margin-right: 1em;
    margin-bottom: 1em;
}

img.justified_center {
    float: none;
    display: block;
    margin: 0 auto 2em;
}

img.justified_full {
    display: block;
    float: none;
    margin: 0 0 1em 0;
}

hr.divider {
    border: none;
    height: 1.6rem;
}

.mce-content-body {
    hr.divider {
        border: 1px dashed #090;
        display: block;
        margin: 1rem 2rem;
        pointer-events: none;
        &::after {
            content: 'Hint: Place cursor above/below block and use up/down arrow keys.';
            color: #090;
            display: block;
            font-size: .8rem;
            line-height: 1.6rem;
            padding-right: .8rem;
            text-align: right;
        }
    }
}

@import '_buttons-and-badges';
@import '_links';
@import '_my-society';
@import '_cpe';
@import '_cart';
@import '_printer';
@import '_edfund';