.profile-photo {
    padding: 0 2rem 1rem 0;
    img {
        border: 3px solid $kycpa-blue;
        height: 165px;
        width: 165px;
    }
}

.person-details {
    .col,
    .col-sm {
        padding-left: 0;
        padding-right: 0;
    }
    .heading {
        border-bottom: 2px solid $kycpa-grey-darkest;
        padding-bottom: .6rem;
        margin-bottom: .6rem;
        .full-name {
            font-size: 1.25em;
        }
    }
    .action {
        a {
            font-size: .9rem;
        }
    }
}

section.block-row {
    position: relative;
    &::before {
        background: #FFF url(/img/blue-swish.png) no-repeat right top;
        background-size: 100%;
        content: '';
        display: block;
        height: 100%;
        right: -3rem;
        position: absolute;
        top: -1rem;
        width: 1300px;
    }
    .credits-earned {
        @include clearfix;
        .number-earned {
            display: block;
            font-size: 3rem;
            float: left;
            line-height: 3rem;
            padding: 0 1rem;
        }
    }
    .credits-upcoming {
        @include clearfix;
        border-top: 2px solid $kycpa-yellow;
        padding-top: 1rem;
        .number-upcoming {
            color: $kycpa-yellow;
            display: block;
            font-size: 2.5rem;
            float: left;
            line-height: 2.5rem;
            padding: 0 1rem;
        }
    }
}

section.dues {
    .message-bar {
        padding-left: 0;
        position: relative;
        &::after {
            background-color: inherit;
            content: '';
            display: block;
            height: 100%;
            right: -3rem;
            position: absolute;
            top: 0;
            width: 9999px;
            z-index: 8888;
        }
        .message-content {
            position: relative;
            z-index: 8889;
        }
    }
}